import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

const HeaderNavMenu = () => {
  const { pathname } = useLocation();

  const isActiveClassName = (path) => {
    return path === pathname ? "active" : "";
  };

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        <li className={cn(isActiveClassName("/"))}>
          <Link to="/">Home</Link>
        </li>
        <li
          className={cn("menu-item-has-children", {
            active: [
              "/project",
              "/project-two",
              "/project-details",
              "/team",
              "/team-details",
              "/estimate",
            ].includes(pathname),
          })}
        >
          <a href="#">Board of Trustees</a>
          <ul className="sub-menu">
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Finance Management</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Youth Management</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Women's Affairs Management</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Children's Affairs Management</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Education Management</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Mosque Management</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Imham's Management</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Madrasa Management</Link>
            </li>
          </ul>
        </li>
        
        <li
          className={cn("menu-item-has-children", {
            active: ["/services", "/services-2", "/services-details"].includes(
              pathname
            ),
          })}
        >
          <a href="#">Quran Madrasa</a>
          <ul className="sub-menu">
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Announcements</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Ongoing Projects</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Gallery</Link>
            </li>
          </ul>
        </li>
        <li
          className={cn("menu-item-has-children", {
            active: ["/blog", "/blog-list", "/blog-details"].includes(pathname),
          })}
        >
          <a href="#">Smart Village</a>
          <ul className="sub-menu">
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Data</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Education & Skills Development</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Empowerment & Livelihood</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Environment</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Health</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Women</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Youth</Link>
            </li>

          </ul>
        </li>

        <li
          className={cn("menu-item-has-children", {
            active: ["/shop", "/shop-details"].includes(pathname),
          })}
        >
          <a href="#">Stakeholders</a>
          <ul className="sub-menu">
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Mahalla CBO's</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Al-Ilma SDC</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Health</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">GS</Link>
            </li>
          </ul>
        </li>
        
        <li
          className={cn("menu-item-has-children", {
            active: ["/blog", "/blog-list", "/blog-details"].includes(pathname),
          })}
        >
          <a href="#">About Us</a>
          <ul className="sub-menu">
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Our History</Link>
            </li>
            <li className={cn(isActiveClassName(""))}>
              <Link to="">Archives</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Gallery</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Staff</Link>
            </li>
          </ul>
        </li>
        <li
          className={cn("menu-item-has-children", {
            active: ["/blog", "/blog-list", "/blog-details"].includes(pathname),
          })}
        >
          <a href="#">More</a>
          <ul className="sub-menu">
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Present Imaams</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Past Projects</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Ongoing Projects</Link>
            </li>
            <li className={cn(isActiveClassName("#"))}>
              <Link to="#">Future Projects</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default HeaderNavMenu;
