export const PricingList = [
  {
    delay: "0.2",
    wowClass: "fadeInLeft",
    url: "/contact",
    title: "Basic Plan",
    price: 75,
    facilities: [
      "Commercial Cleaning",
      "House Cleaning",
      "Outdoor Service",
      "Plumbing Service",
      "Kitchen Cleaning",
    ],
    icon: (
      <>
        <svg viewBox="0 0 47 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M42.5 24.5V38.75C42.5 43.9988 38.2487 48.25 33 48.25H14C8.75125 48.25 4.5 43.9988 4.5 38.75V24.5C4.5 23.1938 5.56875 22.125 6.875 22.125H11.5537C12.86 22.125 13.9287 23.1938 13.9287 24.5V31.9575C13.9287 33.715 14.9025 35.33 16.4462 36.1612C17.135 36.5412 17.895 36.7312 18.6788 36.7312C19.5813 36.7312 20.4838 36.47 21.2675 35.9475L23.5238 34.475L25.6138 35.8763C27.0625 36.85 28.915 36.9687 30.4588 36.1375C32.0263 35.3062 33 33.715 33 31.9338V24.5C33 23.1938 34.0688 22.125 35.375 22.125H40.125C41.4313 22.125 42.5 23.1938 42.5 24.5Z"
            fill="currentcolor"
          />
          <path
            d="M46.0625 12.625V15C46.0625 17.6125 44.8037 19.75 41.3125 19.75H5.6875C2.05375 19.75 0.9375 17.6125 0.9375 15V12.625C0.9375 10.0125 2.05375 7.875 5.6875 7.875H41.3125C44.8037 7.875 46.0625 10.0125 46.0625 12.625Z"
            fill="currentcolor"
          />
          <path
            d="M22.6451 7.87492H9.5351C8.7276 6.99617 8.75135 5.64242 9.60635 4.78742L12.9788 1.41492C13.8576 0.536172 15.3063 0.536172 16.1851 1.41492L22.6451 7.87492Z"
            fill="currentcolor"
          />
          <path
            d="M37.4411 7.87492H24.3311L30.7911 1.41492C31.6698 0.536172 33.1186 0.536172 33.9973 1.41492L37.3698 4.78742C38.2248 5.64242 38.2486 6.99617 37.4411 7.87492Z"
            fill="currentcolor"
          />
          <path
            d="M28.1787 22.125C29.4849 22.125 30.5537 23.1938 30.5537 24.5V31.9338C30.5537 33.8338 28.4399 34.9738 26.8724 33.905L24.7349 32.48C23.9512 31.9575 22.9299 31.9575 22.1224 32.48L19.8899 33.9525C18.3224 34.9975 16.2324 33.8575 16.2324 31.9812V24.5C16.2324 23.1938 17.3012 22.125 18.6074 22.125H28.1787Z"
            fill="currentcolor"
          />
        </svg>
      </>
    ),
  },

  {
    delay: "0.4",
    wowClass: "fadeInUp",
    url: "/contact",
    title: "Standard Plan",
    price: 89,
    facilities: [
      "Commercial Cleaning",
      "House Cleaning",
      "Outdoor Service",
      "Plumbing Service",
      "Kitchen Cleaning",
    ],
    icon: (
      <>
        <svg viewBox="0 0 35 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M31.5362 21.4601H24.1975V4.36012C24.1975 0.370123 22.0362 -0.437377 19.4 2.55512L17.5 4.71637L1.42122 23.0039C-0.787534 25.4976 0.138716 27.5401 3.46372 27.5401H10.8025V44.6401C10.8025 48.6301 12.9637 49.4376 15.6 46.4451L17.5 44.2839L33.5787 25.9964C35.7875 23.5026 34.8612 21.4601 31.5362 21.4601Z"
            fill="currentcolor"
          />
        </svg>
      </>
    ),
  },
  {
    delay: "0.2",
    wowClass: "fadeInRight",
    url: "/contact",
    title: "Premium Plan",
    price: 98,
    facilities: [
      "Commercial Cleaning",
      "House Cleaning",
      "Outdoor Service",
      "Plumbing Service",
      "Kitchen Cleaning",
    ],
    icon: (
      <>
        <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.8412 33.25H9.5C6.8875 33.25 4.75 35.3875 4.75 38V49.875C4.75 51.1812 5.81875 52.25 7.125 52.25H15.8412C17.1475 52.25 18.2162 51.1812 18.2162 49.875V35.625C18.2162 34.3188 17.1475 33.25 15.8412 33.25Z"
            fill="currentcolor"
          />
          <path
            d="M31.6586 23.75H25.3174C22.7049 23.75 20.5674 25.8875 20.5674 28.5V49.875C20.5674 51.1812 21.6361 52.25 22.9424 52.25H34.0336C35.3399 52.25 36.4086 51.1812 36.4086 49.875V28.5C36.4086 25.8875 34.2949 23.75 31.6586 23.75Z"
            fill="currentcolor"
          />
          <path
            d="M47.4999 40.375H41.1587C39.8524 40.375 38.7837 41.4438 38.7837 42.75V49.875C38.7837 51.1812 39.8524 52.25 41.1587 52.25H49.8749C51.1812 52.25 52.2499 51.1812 52.2499 49.875V45.125C52.2499 42.5125 50.1124 40.375 47.4999 40.375Z"
            fill="currentcolor"
          />
          <path
            d="M35.6489 11.5189C36.3852 10.7826 36.6702 9.90389 36.4327 9.14389C36.1952 8.38389 35.4589 7.83764 34.4139 7.67139L32.1339 7.29139C32.0389 7.29139 31.8252 7.12514 31.7777 7.03014L30.5189 4.51264C29.5689 2.58889 27.4077 2.58889 26.4577 4.51264L25.1989 7.03014C25.1752 7.12514 24.9614 7.29139 24.8664 7.29139L22.5864 7.67139C21.5414 7.83764 20.8289 8.38389 20.5677 9.14389C20.3302 9.90389 20.6152 10.7826 21.3514 11.5189L23.1089 13.3001C23.2039 13.3714 23.2752 13.6564 23.2514 13.7514L22.7527 15.9364C22.3727 17.5751 22.9902 18.3114 23.3939 18.5964C23.7977 18.8814 24.6764 19.2614 26.1252 18.4064L28.2627 17.1476C28.3577 17.0764 28.6664 17.0764 28.7614 17.1476L30.8752 18.4064C31.5402 18.8101 32.0864 18.9289 32.5139 18.9289C33.0127 18.9289 33.3689 18.7389 33.5827 18.5964C33.9864 18.3114 34.6039 17.5751 34.2239 15.9364L33.7252 13.7514C33.7014 13.6326 33.7727 13.3714 33.8677 13.3001L35.6489 11.5189Z"
            fill="currentcolor"
          />
        </svg>
      </>
    ),
  },
];
