export const ProjectList = [
  {
    url: "#",
    src: "/img/project/1.jpg",
    src2: "/img/project/1.jpg",
    title: "Event Name",
  },
  {
    url: "#",
    src: "/img/project/2.jpg",
    src2: "/img/project/2.jpg",
    title: "Event Name",
  },
  {
    url: "#",
    src: "/img/project/3.jpg",
    src2: "/img/project/3.jpg",
    title: "Event Name",
  },
  {
    url: "#",
    src: "/img/project/4.jpg",
    src2: "/img/project/4.jpg",
    title: "Event Name",
  },
  {
    url: "#",
    src: "/img/project/5.jpg",
    src2: "/img/project/5.jpg",
    title: "Event Name",
  },
  {
    url: "#",
    src: "/img/project/06.jpg",
    src2: "/img/project/6.jpg",
    title: "Event Name",
  },
];
