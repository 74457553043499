import React, { useEffect } from "react";
import AboutUs from "../../components/AboutUs/AboutUs";
import BlogAreaOne from "../../components/Blogs/BlogAreaOne";
import BrandArea from "../../components/BrandArea/BrandArea";
import ContactAreaOne from "../../components/Contact/ContactAreaOne";
import CounterAreaOne from "../../components/CounterAreas/CounterAreaOne";
import ProjectAreaOne from "../../components/ProjectAreas/ProjectAreaOne";
import ServicesArea from "../../components/ServicesArea/ServicesArea";
import SliderAreaOne from "../../components/SliderArea/SliderAreaOne";
import TeamOne from "../../components/Team/TeamOne";
import TestimonialAreaOne from "../../components/Testimonials/TestimonialAreaOne";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";
import ServicesAreaTwo from "../../components/ServicesArea/ServicesAreaTwo";
import IntroductionAreaOne from "../../components/Introduction/IntroductionAreaOne";
import FaqAreaOne from "../../components/FaqArea/FaqAreaOne";
import ProjectAreaTwo from "../../components/ProjectAreas/ProjectAreaTwo";
import BlogAreaTwo from "../../components/Blogs/BlogAreaTwo";
import HowWeWork from "../../components/HowWeWork/HowWeWork";
import AboutUsTwo from "../../components/AboutUs/AboutUsTwo";
import ServicesAreaFour from "../../components/ServicesArea/ServicesAreaFour";
import VideoArea from "../../components/VideoArea/VideoArea";
import TeamTwo from "../../components/Team/TeamTwo";
import ProjectAreaThree from "../../components/ProjectAreas/ProjectAreaThree";
import ServicesAreaFive from "../../components/ServicesArea/ServicesAreaFive";







const HomeFour = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={1} footer={2} topHeaderClassName={""}>
        <SliderAreaOne />
        <ServicesAreaTwo />
        <IntroductionAreaOne />
        <AboutUs />
        <AboutUsTwo />
        <ServicesArea />
        <ServicesAreaFour />
        <ServicesAreaFive />
        <FaqAreaOne />
        <HowWeWork />
        <VideoArea />
        <TeamOne />
        <TeamTwo />
        <CounterAreaOne />
        <ProjectAreaTwo />
        <ProjectAreaThree />
        <ProjectAreaOne />
        <TestimonialAreaOne />
        <BrandArea />
        <ContactAreaOne />
        <BlogAreaTwo />
        <BlogAreaOne />
      </Layout>
    </>
  );
};

export default HomeFour;
