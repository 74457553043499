import SlickSlider from "../SlickSlider/SlickSlider";
import SliderAreaOneItem from "./SliderAreaOneItem";
import $ from "jquery";
import { doAnimations } from "../../lib/helpers";

const SliderAreaOne = () => {
  const slick_settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    fade: true,
    arrows: false,
    responsive: [{ breakpoint: 767, settings: { dots: false, arrows: false } }],
    onInit: () => {
      var $firstAnimatingElements = $(".single-slider:first-child").find(
        "[data-animation]"
      );
      doAnimations($firstAnimatingElements);
    },
    beforeChange: (currentSlide, nextSlide) => {
      var $animatingElements = $(
        '.single-slider[data-slick-index="' + nextSlide + '"]'
      ).find("[data-animation]");
      doAnimations($animatingElements);
    },
  };

  const slider_area_one_items = [
    {
      bg: "/img/slider/slide1.jpg",
      subtitle: "Prayer",
      title: "Establish regular prayers (Quran 2:43)",
    },
    {
      bg: "/img/slider/slide2.jpg",
      subtitle: "Helping Others",
      title: "And do good to parents, relatives (Quran 4:36)",
    },
    {
      bg: "/img/slider/slide3.jpg",
      subtitle: "Zakat",
      title: "Give zakat, purify your wealth (Quran 9:103)",
    },
  ];

  return (
    <section className="slider-area">
      <div className="slider-active">
        <SlickSlider settings={slick_settings}>
          {slider_area_one_items.map((x, index) => (
            <SliderAreaOneItem key={index} item={x} />
          ))}
        </SlickSlider>
      </div>
    </section>
  );
};

export default SliderAreaOne;
