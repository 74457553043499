import React from "react";
import cn from "classnames";

const HeaderTop = ({ className }) => {
  return (
    <div className={cn("header-top", className)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="header-top-left">
              <span>
                <i className="fas fa-mosque"></i> Some features are temporarily unavailable due to a planned upgrade. Please check back soon.
              </span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="header-top-right">
              <span>
                <i className="far fa-clock"></i> Beta | Pre-release Version
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
