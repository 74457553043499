export const TestimonialItemsArray = [
  {
    src: "/img/images/avatar_img01.png",
    title: "Dan Cliford",
    designation: "CEO Clef Inc",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus eget ultrices pretium nisi amet facilisis
    cursus amus ommodo dictum.`,
  },
  {
    src: "/img/images/avatar_img02.png",
    title: "Samuel Peters",
    designation: "CEO Clef Inc",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus eget ultrices pretium nisi amet facilisis
    cursus amus ommodo dictum.`,
  },
  {
    src: "/img/images/avatar_img01.png",
    title: "Dan Cliford",
    designation: "CEO Clef Inc",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus eget ultrices pretium nisi amet facilisis
    cursus amus ommodo dictum.`,
  },
  {
    src: "/img/images/avatar_img02.png",
    title: "Samuel Peters",
    designation: "CEO Clef Inc",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus eget ultrices pretium nisi amet facilisis
    cursus amus ommodo dictum.`,
  },
];
