export const TeamOneItemsArray = [
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M.M.M.Wazeer",
    designation: "Hony. President",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "B.M. Haneefa",
    designation: "Hony. Secretary",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M. I. M. Nusky",
    designation: "Hony. Treasuer",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M. M. M. Rismy",
    designation: "Hony. Vice President",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M.R. M. Fathullah",
    designation: "Hony. Vice Secretary",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M. F. A. Thariq",
    designation: "Hony. V. Treasurer",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "A. H. M. Faizal",
    designation: "Hony. Member",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M. H. M. Nisthar",
    designation: "Hony. Member",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M. S. M. Rizvi",
    designation: "Hony. Member",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M. N. M. Risard",
    designation: "Hony. Member",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "M. S. M. Jouhar",
    designation: "Hony. Member",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "L. H. M. Ismail",
    designation: "Hony. Member",
    url: "#",
  },
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "A. C. M. Irsad",
    designation: "Hony. Member",
    url: "#",
  },
];
