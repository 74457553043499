export const ProgressBarItems = [
  {
    title: "Cleaning",
    delay: "0.2s",
    width: 87,
  },
  {
    title: "Washing",
    delay: "0.4s",
    width: 94,
  },
  {
    title: "Plumbing Services",
    delay: "0.5s",
    width: 79,
  },
  {
    title: "Indoor Cleaning",
    delay: "0.6s",
    width: 85,
  },
];
