import React from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Iframe from "react-iframe";

const IntroductionAreaOne = () => {
  return (
    <section id="intro" className="introduction-area pb-130">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="introduction-img-wrap">
              <img src="/img/images/introduction_img01.png" alt="" />
              <img
                src="/img/images/introduction_img02.png"
                data-aos="fade-right"
                alt=""
              />

              <Popup
                trigger={
                  <div className="play-btn">
                    <a
                      href="#intro"
                      className="popup-video"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                }
                position=""
                modal={true}
              >
                <Iframe
                  url="https://www.youtube.com/embed/QV3rAwvvVTs"
                  width="100%"
                  height="350px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </Popup>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="introduction-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style2">
                <span className="sub-title">Introduction to</span>
                <h2 className="title tg-element-title">
                  Smart Village
                </h2>
              </div>

              <p className="info-one">
              ஒரு கிராமத்தின் பள்ளிவாயலை மையப்படுத்திய சகலதுறை நிலையான அபிவிருத்தியை நோக்கியா ஒரு சமூக எழுச்சி திட்டமாகவே இந்த திட்டம் அறிமுகப்படுத்தப்பட்டது. 

              </p>
              <p>
              ஸ்மார்ட் கிராம நிகழ்ச்சித்திட்டம்’ என்பது டிஜிடல் தொழில்நுட்ப உதவியுடன் ஆய்வு முறைமைகளைப் பிரயோகித்து பிரதேச சமூக பொருளாதார நிலைமைகளை உரிய தரவுகளின் உதவியுடன் ஆராய்ந்து குறித்த பிரதேச மக்களின் ஒன்றிணைந்த பங்கேற்புடன் பிரதேச வளங்களை ஒன்றுதிரட்டியதான சமூக மாற்றத்தை ஏற்படுத்தும் ஒரு நிலையான அபிவிருத்தி முறையமையாகும். இங்கு கிராமிய மக்களின் கல்வி, சுகாதாரம், சுற்றாடல், உணவுப்பாதுகாப்பு, புத்தாக்கம், மற்றும் டிஜிட்டல் தொழில்நுட்பம் ஆகிய விடயங்கள் தொடர்பாக கவனம் செலுத்தப்படுகிறது.
              </p>

              <div className="introduction-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>Data
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Environment
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Health
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Women
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Youth
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Empowerment & Livelihood
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Education & Skills Development
                  </li>
                  
                </ul>
              </div>

              <div className="introduction-bottom">
                <Link to="/img/images/Smart_Village.pdf" className="btn">
                  Discover MORE
                </Link>

                <span className="call-now">
                  <i className="fas fa-phone-alt"></i>
                  <a href="tel:94761624433">+94 (76) 162 4433</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroductionAreaOne;
